/**
 * 游戏列表
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../component/container";
import SearchBar from '../component/searchBar';
import global from "../global";
import model from "../services/model";
import GameCard from '../component/gameCard';
import style from '../utils/config.scss'
import history from "../services/history";
import PublicUtils from "../utils/publicUtils";
import Image from '../component/image'

export default class GameList extends React.PureComponent {
    // 构造
    constructor(props) {
        super(props);
        this.params = {
            name: PublicUtils.getState(this.props.location.pathname).title,
            type: PublicUtils.getState(this.props.location.pathname).type,
            game_id: PublicUtils.getState(this.props.location.pathname).game_id,
            title: PublicUtils.getState(this.props.location.pathname).title,
            game_list_img_path: global.RES_URL + (PublicUtils.getState(this.props.location.pathname).game_list_img_path),
            game_list_json_name: PublicUtils.getState(this.props.location.pathname).game_list_json,
        };

        this.state = {
            title: PublicUtils.getState(this.props.location.pathname).title,
            game_list_json: model[this.params.game_list_json_name]
        };

    }

    // static navigationOptions = ({navigation}) => {
    //     return {
    //         title: navigation.getParam("title")
    //     }
    // };

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
       if(global.ID == 'br'){
           let data = this.state.game_list_json;
           data.gameList = data.gameList.filter(item=>  item.HTML5_Game_ID != 'YP825');
           this.setState({
               game_list_json:data
           })
       }
        // this.Api.get("assets/json/" + this.game_list_json_name, {}, (res) => {
        //   this.isloading = false;
        //   this.game_list_json = res;
        //   this.initItem = res;
        // }, "loader");
        // this.game_list_json = this.commonData[this.game_list_json_name];
        // this.setState({game_list_json: model[this.params.game_list_json_name]})


    }

    render() {
        return (
            <Container title={this.state.title} style={{backgroundColor: "#fff"}}>
                <div style={styles.searchBarBox}>
                    <SearchBar onPress={() => {
                        PublicUtils.navigate('SearchView');
                    }}/>
                </div>
                <div style={{marginTop: 10}}>
                    <div style={{height: 10, backgroundColor: "#f5f7f9"}}/>
                    {

                        this.state.game_list_json.activeList && this.state.game_list_json.activeList.map((item, key) => {
                            let icon = item.logo ? this.params.game_list_img_path + item.logo : null;
                            return (
                                <a target={'_blank'} style={{width: "100%"}} href={item.href}><Image hasPlaceholder={true}
                                    placeholderSize={{
                                        width: model.clientWidth,
                                    }}
                                    width={model.clientWidth}
                                    uri={icon}/></a>
                            )
                        })
                    }
                    <div style={styles.columnView}>
                        {
                            this.state.game_list_json.gameList.map((item, key) => {
                                let icon = item.Button_Image_Name ? this.params.game_list_img_path + item.Button_Image_Name : null;
                                let obj = item;
                                obj.type = model[this.params.game_list_json_name].type;
                                obj.id = model[this.params.game_list_json_name].id;
                                obj.game_id = this.params.game_id;
                                return (
                                    <GameCard width={((model.clientWidth - 50) / 4)} obj={item}
                                              icon={icon}
                                              name={item.CN_Name}/>
                                )
                            })
                        }
                    </div>
                </div>
            </Container>
        )
    }
}

const styles = {
    searchBarBox: {
        marginTop: 20,
        display: "flex",
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: "#fff",
        flexDirection: "column",
    },
    columnView: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        padding: 5
    },
};
