import Storage from "../services/storage";
import Http from "../services/http";
import model from "../services/model";
import React from "react";
import history from '../services/history'
import Toast from '../component/toast/index';
import global from "../global";


/**
 * 公共工具
 */
function postcall(url, params, target) {
    let tempform = document.createElement("form");
    tempform.action = url;
    tempform.method = "post";
    tempform.style.display = "none";
    if (target) {
        tempform.target = target;
    }

    for (let x in params) {
        let opt = document.createElement("input");
        opt.name = x;
        opt.value = params[x];
        tempform.appendChild(opt);
    }

    let opt = document.createElement("input");
    opt.type = "submit";
    tempform.appendChild(opt);
    document.body.appendChild(tempform);
    tempform.submit();
    document.body.removeChild(tempform);
}

class PublicUtils extends React.Component {
    // 构造
    constructor(props) {
        super(props);
    }

    /**
     * 消息
     * @param msg
     * @param option
     */
    static showToast = (msg, option?: Object) => {
        Toast.info(msg, (option && option.duration) || 3000);
    };
    /**
     * 消息
     * @param msg
     * @param option
     */
    static showLoder = (msg, option?: Object) => {
        Toast.loading(msg);
    };
    /**
     * 消息
     * @param msg
     * @param option
     */
    static hideLoader = () => {
        Toast.hideLoader();
    };
    /**
     * 对话框
     * @param msg
     * @param option
     */
    static alert = (option) => {
        Toast.alert(option);
    };
    /**
     * 跳转页面
     */
    static navigate = (path, param) => {
        path = {
            pathname: path,
            state: param,
        };
        PublicUtils.setState(path.pathname, param);
        history.push(path);
    };

    static checkPing = () => {

        for (let i = 0; i < model.lines.length; i++) {
            PublicUtils.ping(model.lines[i], (obj) => {
                PublicUtils.setDelayTime(obj);
            })
        }
    }


    static ping = (line, callback) => {
        let delay = 0;
        const starttime = new Date().getTime();
        let url = line.url;
        // console.log(url);
        if (line.type === 1) {
            url = line.url + global.ID + "/notice";
        } else {
            url = line.url + "mobile/notice";
        }
        fetch(url)
            .then((response) => {

                const endtime = new Date().getTime();
                const obj = {
                    url: line.url,
                    delay: endtime - starttime
                };
                callback(obj);
                // if (response.status === 200) {
                //     console.log('success');
                // } else {
                //     console.log('error');
                // }
            })
            .catch((error) => {
                console.log('network error: ' + error);
                const obj = {
                    url: line.url,
                    delay: 999
                };
                callback(obj);
            })
    }

    static setDelayTime = (obj) => {
        let lines = model.lines;
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].url === obj.url) {
                lines[i].delay = obj.delay !== 999 ? Math.round(obj.delay / 8) : obj.delay;
                let status = 0;
                if (lines[i].delay < 200) {
                    status = 1;
                } else if (lines[i].delay >= 200 && lines[i].delay < 400) {
                    status = 2;
                } else if (lines[i].delay >= 400 && lines[i].delay < 999) {
                    status = 3;
                } else {
                    status = 4;
                }
                lines[i].status = status;
            }
        }
        // console.log(lines);
    }


    static getBestUrl = (callback) => {
        PublicUtils.checkPing();
        let checked = false;
        let checkedCount = 0;
        let bestUrl = model.lines[0];

        // PublicUtils.showToast("正在获取最优线路");
        const timer = window.setInterval(() => {
            if (checked) {
                const obj = {status: 1, msg: "备用线路已找到"};
                callback(obj)
                window.clearInterval(timer);
            }
            for (let i = 0; i < model.lines.length; i++) {
                if (model.lines[i].delay && model.lines[i].delay !== 999) {
                    bestUrl = model.lines[i];

                    // 用户已选择线路处理
                    let yusr = localStorage.getItem('line');
                    if(yusr){
                        Storage.storeData({line: yusr});
                    }else{
                        global.APP_API_URL = bestUrl;
                        Storage.storeData({line: JSON.stringify(bestUrl)});
                    }


                    checked = true;
                    break;
                }
            }
            // if (checkedCount == model.lines.length) {
            //     // this.cycleUrl();
            //     checked = true;
            // }
        }, 100)
        const failTimer = setTimeout(() => {
            if (!checked) {
                const obj = {status: 0, msg: "未找到可用线路，请检查网络"};
                callback(obj)
                clearTimeout(failTimer);
            }
        }, 15000)

    }
    /**
     * 替换当前页面
     */
    static  replaceNavigate = (path, param) => {
        path = {
            pathname: path,
            state: param,
        };
        PublicUtils.setState(path.pathname, param);
        history.replace(path);
    };
    /**
     * 返回上一页
     */
    static goBack = () => {
        history.goBack();
    };
    /**
     * 保存页面参数
     * @param param
     */
    static setState = (path, param) => {
        if (param) {
            let state = Storage.retrieveData("state") ? JSON.parse(Storage.retrieveData("state")) : {};
            state[path] = JSON.stringify(param);
            Storage.storeData({state: JSON.stringify(state)});
        }
    };
    /**
     * 保存页面参数
     * @param param
     */
    static getState = (pathname) => {
        // debugger
        let state = "";
        if (Storage.retrieveData("state") && JSON.parse(Storage.retrieveData("state"))[pathname.split('/')[1]]) {
            state = JSON.parse(JSON.parse(Storage.retrieveData("state"))[pathname.split('/')[1]]);
        }
        return state;
    };

    static linking = (url, target) => {
        let link = document.createElement('a');
        link.href = url;
        link.style.display = "none";
        if (target) {
            link.target = target;
        }
        let body = document.getElementsByTagName('body')[0];
        body.appendChild(link);
        link.click();
        body.removeChild(link);
    };
    /**
     * 进入游戏
     * @private
     */
    static _enterGamePage = (parm) => {
        PublicUtils.showToast("载入中...", {});
        // this.setState({showGameInProgress: true});
        Http.request("gameIn", parm, (res) => {
            // this.setState({showGameInProgress: false});
            if (res.code === 300) {
                Storage.clearData();
                PublicUtils.replaceNavigate("/login", {tips: model.tips.loginTimeOut, nextPage: 'Home'});
            }
            if (res.code === 200) {
                if (res.data.status === 1) {
                    // if (parm.name === "BBIN") {
                    //     let parm = {};
                    //     let paramArray = res.data.url.split('?');
                    //     if (paramArray.length > 1) {
                    //         const paramItem = paramArray[1].split('&');
                    //         for (let i = 0; i < paramItem.length; i++) {
                    //             parm[paramItem[i].split('=')[0]] = paramItem[i].split('=')[1];
                    //         }
                    //     }
                    //     postcall(paramArray[0], parm);
                    // } else {
                    //     window.location.href = res.data.url;
                    // }
                    console.log(parm);
                    if (parm.name === "DS") {
                        postcall(res.data.url, res.data.info);
                    } else {
                        PublicUtils.linking(res.data.url);
                    }
                    // PublicUtils.linking(res.data.url);
                } else {
                    PublicUtils.showToast(res.data.message);
                }
            }
        });
    };


    /**
     * 进去彩票游戏
     * @param id
     */
    static _enterLotteryPage = (id) => {
        let parm = {code: id};
        switch (id) {
            case 7001:
                PublicUtils.navigate('PCDD', parm);
                break;
            case 6001:
                PublicUtils.navigate('PKS', parm);
            case 6002:
                PublicUtils.navigate('PKS', parm);
                break;
            case 1001:
                PublicUtils.navigate('LHC', parm);
                break;
            case 2003:
                PublicUtils.navigate('SSC', parm);
                break;
            case 2001:
                PublicUtils.navigate('SSC', parm);
                break;
            case 2002:
                PublicUtils.navigate('SSC', parm);
                break;
            case 4002:
                PublicUtils.navigate('KLSF', parm);
                break;
            case 4001:
                PublicUtils.navigate('KLSF', parm);
                break;
            case 3002:
                PublicUtils.navigate('KS', parm);
                break;
            case 3003:
                PublicUtils.navigate('KS', parm);
                break;
            case 3001:
                PublicUtils.navigate('KS', parm);
                break;
            default:
                PublicUtils.showToast("游戏维护中...")

        }
    }
    /**
     * 进入游戏
     * @private
     */
    static _enterGame = (item) => {
        if (item && item.game_type === "_self_lottery") {
            PublicUtils._enterLotteryPage(item.game_id);
        } else {
            let parm = {
                name: item.game_api,
                type: item.type,
                game_id: item.game_id,
                title: item.name,
                game_list_json: item.game_list_json,
                game_list_img_path: item.game_list_img_path
            };
            if (model[item.game_list_json]  && item.game_id !== 408) {
                PublicUtils.navigate('GameList', parm);
            } else {
                // PublicUtils.showToast("该游戏厅不存在！")

                if (item.game_id === 407 || item.game_id === 408) {
                    let parm = {
                        name: item.game_api,
                        game_id: item.game_id,
                        type: item.type,
                        play: "game",
                    };
                    PublicUtils._enterGamePage(parm);
                } else if (item.game_id === 201) {
                    PublicUtils.navigate('GameNavigation');
                } else if (item.game_id === 101) {
                    PublicUtils.navigate('HG');
                } else {
                    //this.Api.navPush("GameTransferPage", this.navCtrl, parm, true);
                    PublicUtils._enterGamePage(parm);

                    // PublicUtils._enterGamePage(parm);
                }

            }
        }
    };


//去除字符串左空格
    static
    ltrim = function (s) {
        return s.replace(/^(\s*| *)/, '');
    };
//去除字符串右空格
    static
    rtrim = function (s) {
        return s.replace(/(\s*| *)$/, '');
    };
//去除字符串左右空格
    static
    trim = function (s) {
        return this.ltrim(this.rtrim(s));
    };
//去除字符串中所有空格
    static
    trim_all = function (text) {
        text = text.replace(/^\s+/, '');
        for (let i = text.length - 1; i >= 0; i--) {
            if (/\S/.test(text.charAt(i))) {
                text = text.substring(0, i + 1);
                break;
            }
        }
        return text;
    };
    /**
     * 验证登录
     * @private
     */
    static
    _checkLogin = (navigation) => {
        const token = Storage.retrieveData("token");
        if (!token) {
            return false;
        } else {
            return true;
        }
    };
    /**
     * 保存会员信息(仅登录成功之后调用)
     */
    static
    saveUserInfo = (data) => {
        for (let key in data) {
            let value;
            if (key == "user") {
                value = JSON.stringify(data[key]);
            } else {
                value = data[key];
            }
            let item = {};
            item[key] = value;
            Storage.storeData(item);
        }
    };
    /**
     * 保留两位小数
     * @param num
     * @returns {string|*}
     */
    static
    subStringNum = (num) => {
        let result;
        let zs = num;
        let xs = '';
        num = num.toString();
        if (num.indexOf('.') > -1) {
            zs = num.substring(0, num.indexOf('.'));
            xs = num.substr(num.indexOf('.') + 1);
        }
        let lw_xs = xs.substring(0, 2);
        const lw_xs_length = lw_xs.length;
        for (let i = lw_xs_length; i < 2; i++) {
            lw_xs += "0";
        }
        result = zs + "." + lw_xs;
        return result;
    };
    /**
     * 排列组合算法
     * @param m
     * @param n
     * @returns {number}
     */
    static
    combination = (n, m) => {
        // console.log("--------------------------------");
        // console.log(PublicUtils.factorial(n));
        // console.log(PublicUtils.factorial(n - m));
        // console.log(PublicUtils.factorial(m));
        // return parseInt(PublicUtils.factorial(n) / (parseInt(PublicUtils.factorial(n - m) * PublicUtils.factorial(m))));
        return Math.round(PublicUtils.factorial1(n, n) / PublicUtils.factorial1(m, m) / PublicUtils.factorial1(n - m, m - n));
        // return PublicUtils.factorial1(n, n) / PublicUtils.factorial1(m, m) / PublicUtils.factorial1(n - m, m - n);
    };
    static
    factorial1 = (m, n) => {
        var num = 1;
        var count = 0;
        for (var i = m; i > 0; i--) {
            if (count == n) {
                break;
            }
            num = num * i;
            count++;
        }
        return num;
    }
    /**
     * 阶乘
     */
    static
    factorial = (n) => {
        let count = 1;
        for (let i = n; i > 0; i--) {
            count = count * i;
        }
        return count;
    };
    /**
     * 修改会员信息
     * @param obj
     * @returns {Promise<void>}
     */
    static
    updateUserInfo = (obj) => {
        let userInfo = JSON.parse(Storage.retrieveData("user"));
        for (let key in obj) {
            userInfo[key] = obj[key];
        }
        Storage.storeData({user: JSON.stringify(userInfo)});
    };
    static
    bankIconTransform = (value) => {
        if (value === '北京农商银行') {
            return 'bank1';
        } else if (value === '北京银行') {
            return 'bank2';
        } else if (value === '中国工商银行') {
            return 'bank3';
        } else if (value === '中国光大银行') {
            return 'bank4';
        } else if (value === '广发银行') {
            return 'bank5';
        } else if (value === '华夏银行') {
            return 'bank6';
        } else if (value === '中国建设银行') {
            return 'bank7';
        } else if (value === '交通银行') {
            return 'bank8';
        } else if (value === '中国民生银行') {
            return 'bank9';
        } else if (value === '中国农业银行') {
            return 'bank10';
        } else if (value === '平安银行') {
            return 'bank11';
        } else if (value === '浦发银行') {
            return 'bank12';
        } else if (value === '上海农商银行') {
            return 'bank13';
        } else if (value === '上海银行') {
            return 'bank14';
        } else if (value === '兴业银行') {
            return 'bank15';
        } else if (value === '中国银联') {
            return 'bank16';
        } else if (value === '中国邮政储蓄银行') {
            return 'bank17';
        } else if (value === '招商银行') {
            return 'bank18';
        } else if (value === '中国银行') {
            return 'bank19';
        } else if (value === '中信银行') {
            return 'bank20';
        } else {
            return 'onlinepay';
        }
    };
    /**
     * 将时间转换为 刚刚 今天 昨天
     * @param timestamp
     * @param {number} type 是否需要显示时间 2:不显示
     * @returns {string}
     */
    static
    timestampFormat = (timestamp, type) => {
        let timeArray = timestamp.split(/[^0-9]/);
        timestamp = new Date(timeArray[0], timeArray[1] - 1, timeArray[2], timeArray[3], timeArray[4], timeArray[5]);
        timestamp = Date.parse(timestamp) / 1000;

        function zeroize(num) {
            return (String(num).length == 1 ? '0' : '') + num;
        }

        let curTimestamp = new Date().getTime() / 1000; //当前时间戳
        let timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数

        let curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
        let tmDate = new Date(timestamp * 1000);  // 参数时间戳转换成的日期对象

        let Y = tmDate.getFullYear(), m = tmDate.getMonth() + 1, d = tmDate.getDate();
        let H = tmDate.getHours(), i = tmDate.getMinutes(), s = tmDate.getSeconds();

        if (timestampDiff < 60) { // 一分钟以内
            return "刚刚";
        } else if (timestampDiff < 3600) { // 一小时前之内
            return Math.floor(timestampDiff / 60) + "分钟前";
        } else if (curDate.getFullYear() == Y && curDate.getMonth() + 1 == m && curDate.getDate() == d) {
            let timeStr = "";
            if (type === 2) {
                timeStr = '今天';
            } else {
                timeStr = '今天' + zeroize(H) + ':' + zeroize(i);
            }
            return timeStr;
        } else {
            let newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
            if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
                let timeStr = "";
                if (type === 2) {
                    timeStr = '昨天';
                } else {
                    timeStr = '昨天' + zeroize(H) + ':' + zeroize(i);
                }
                return timeStr
            } else if (curDate.getFullYear() == Y) {
                let timeStr = "";
                if (type === 2) {
                    timeStr = zeroize(m) + '月' + zeroize(d) + '日 ';
                } else {
                    timeStr = zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
                }
                return timeStr
            } else {
                let timeStr = "";
                if (type === 2) {
                    timeStr = Y + '年' + zeroize(m) + '月' + zeroize(d) + '日 ';
                } else {
                    timeStr = Y + '年' + zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
                }
                return timeStr;
            }
        }
    };

    static hello() {
        let today = new Date();
        var day;
        var date;
        var hello;
        let hour = new Date().getHours();
        if (hour < 6) hello = '凌晨好，';
        else if (hour < 9) hello = '早上好，';
        else if (hour < 12) hello = '上午好，';
        else if (hour < 14) hello = '中午好，';
        else if (hour < 17) hello = '下午好，';
        else if (hour < 19) hello = '傍晚好，';
        else if (hour < 24) hello = '晚上好，';
        else {
            hello = '夜深了，'
        }
        return hello;
    }
}

export default PublicUtils;
